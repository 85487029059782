<template>
  <div>
    <Heard />

    <div class="diag-box container">
      <div class="left-box">
        <el-image v-if="img" :src="img" alt="" />
      </div>
      <div class="right-box">
        <div class="number">
          <el-progress
            type="circle"
            :show-text="false"
            :percentage="score"
            :stroke-width="10"
            color="#FF8800"
            :width="180"
          ></el-progress>
          <div class="isshownum">
            <p class="one">诊断分数</p>
            <p class="two">{{ score }}</p>
          </div>
          <div class="tips">
            {{ diag.summary }}
          </div>
        </div>
        <div class="suggest">
          <h2>修改建议</h2>
          <el-divider></el-divider>

          <el-collapse v-model="actname">
            <el-collapse-item name="1">
              <template slot="title">
                个人简历<i class="header-icon el-icon-info"></i>
              </template>
              <div v-for="(item, index) in info" :key="index">
                {{ item }}
              </div>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                工作经历<i class="header-icon el-icon-info"></i>
              </template>
              <div>
                {{ work.workSummary }}
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                项目经历<i class="header-icon el-icon-info"></i>
              </template>
              <div v-for="(item, index) in pro" :key="index">{{ item }}</div>
            </el-collapse-item>
            <el-collapse-item name="4">
              <template slot="title">
                教育经历<i class="header-icon el-icon-info"></i>
              </template>
              <div v-for="(item, index) in edu" :key="index">{{ item }}</div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
import { getUser, getfile } from "@/plugins/auth.js";

import { api_intelligentResumeDiagnosis } from "@/apis/apis";

export default {
  components: {
    Heard,
  },
  data() {
    return {
      user: {},
      files: [],
      diag: {},
      info: [], //个人简历
      work: [], //工作经历
      pro: [], //项目经历
      edu: [], //教育经历
      score: 0, //分数
      actname: "1",
      img: "",
    };
  },
  computed: {
    file() {
      return this.$store.state.userstore.file;
    },
  },
  async created() {
    this.user = getUser();
    this.files = getfile();
    // console.log(this.files.file.split(","));
    if (this.files.file) {
      let res = await api_intelligentResumeDiagnosis({
        userId: this.user.id,
        urls: this.files.file.split(","),
      });
      console.log(res.data.data, "all");
      this.diag = res.data.data;
      this.info = JSON.parse(this.diag.base).content;
      this.work = JSON.parse(this.diag.work);
      this.pro = JSON.parse(this.diag.pro).content;
      this.edu = JSON.parse(this.diag.edu).content;
      this.score = Number(this.diag.score);
      this.img = res.data.data.img;

      //   console.log(JSON.parse(this.diag.base).content, "xindayin");
    }
  },
};
</script>

<style lang="less" scoped>
.diag-box {
  display: flex;
  .left-box {
    width: 820px;
    height: 1200px;
    -background: #e9e9e9;
    .el-image {
      width: 820px;
    }
  }
  .right-box {
    margin-left: 20px;
    .number {
      width: 360px;
      height: 340px;
      padding: 40px 20px 30px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      display: flex;
      flex-direction: column;
      position: relative;
      .el-progress {
        margin: 0 auto;
      }
      .isshownum {
        color: red;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        .one {
          height: 21px;
          font-size: 16px;
          color: #000000;
        }
        .two {
          height: 74px;
          text-align: center;
          font-size: 56px;
          font-weight: bold;
          color: #ff8800;
        }
      }
      .tips {
        margin-top: 28px;
        height: 43px;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .suggest {
      margin-top: 20px;
      padding: 20px;
      h2 {
        height: 26px;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
      }
    }
  }
}
</style>